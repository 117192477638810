import axios from 'axios';
import md5 from 'md5';
import React, { useEffect, useRef, useState } from 'react'
import { useLanguage } from './LanguageContext';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from './config';

const QukiLink = () => {
    const [quckLinkData, setQuckLinkData] = useState()
    const { selectedLanguage, changeLanguage } = useLanguage();
    const [showLink, setShowLink] = useState(false)
    const [show, setShow] = useState()
    const navigate = useNavigate()
    const quickLinkRef = useRef(null);

    const date = new Date()
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    }
    const dateFomate = formatDate(date)
    const key = 'KDCC'
    const token = md5(dateFomate + key)

    useEffect(() => {
        axios.post(`${API_BASE_URL}/headerdata`, { lang: selectedLanguage }, { headers: { 'X-Custom-Token': token } })
            .then((res) => {
                setQuckLinkData(res?.data?.data?.QuickLink)
            })
            .catch((err) => {
            })
    }, [selectedLanguage, token])

    const heandelClickLink = (el) => {
        navigate(el)
        setShowLink(false)
    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (quickLinkRef.current && !quickLinkRef.current.contains(event.target)) {
                setShowLink(false); // Close quick links if click is outside the container
            }
        };

        // Add event listener for clicks
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Clean up the event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [quickLinkRef]);
    return (
        <>
            <div className='wave-effect' onClick={() => setShowLink(!showLink)}>
                <img src={'https://www.kdccbank.in/i.png'} alt="" />
            </div>

            {showLink &&
                <div class='rounded-5 rounded-bottom-0  ' ref={quickLinkRef} style={{ width: 'auto', position: 'fixed', bottom: '110px', right: '40px', zIndex: '999', display: 'grid', justifyContent: "end" }}>
                    {quckLinkData?.map((el, i) => {
                        return (
                            <div class=' align-items-center  d-flex justify-content-end gap-3' style={show == `link${i}` ? { background: '#FD5900', width: 'auto', height: '50px', borderRadius: '50px', marginTop: '20px', transition: '1s' } : { background: 'transparent', width: 'auto', height: '50px', borderRadius: '50px', marginTop: '20px', transition: '0.5s' }} >
                                <div style={show == `link${i}` ? { width: 'auto', transition: '0.5s' } : { width: 'auto', transition: '0.5s' }}>
                                    {
                                        show == `link${i}` &&
                                        <p style={{ marginLeft: '10px', color: 'white' }}>{el?.name}</p>
                                    }
                                </div>
                                <div class='d-flex align-items-center justify-content-center' style={{ width: '50px', height: '50px', background: '#FD5900', borderRadius: '50%' }} onClick={() => heandelClickLink(el?.url)} onMouseEnter={() => setShow(`link${i}`)} onMouseLeave={() => setShow(``)}>

                                    <div style={{ width: '25px', height: '25px', }}>
                                        <img src={el?.icon} style={{ width: '100%', height: '100%', cursor: 'pointer' }} alt="" />
                                    </div>
                                </div>

                            </div>

                        )
                    })}

                </div>
            }
        </>
    )
}

export default QukiLink